<template>
  <div>
    <h2 class="text-center">合同执行额分布</h2>
    <Row class="p-b-10">
      <i-col span="16" class="title">合同执行额（时间段内合计）</i-col>
      <i-col span="8" class="text-right">{{formatMoney(pagedata_contractExecuteAmount)}}</i-col>
    </Row>

    <div style="width:100%;height:250px;" id="workMonthReport"></div>

    <Row style="margin-top: -30px;">
      <i-col span="16" class="title">客流量</i-col>
      <i-col span="8" class="text-right">{{formatNumber(pagedata_PassengerFlow)}} 人次</i-col>
    </Row>
    <Row>
      <i-col span="16" class="title">客流数据更新日期</i-col>
      <i-col span="8" class="text-right">{{pagedata_LastUpdateDate}}</i-col>
    </Row>
    <Row>
      <i-col span="16" class="title">Arpu值</i-col>
      <i-col span="8" class="text-right">{{pagedata_arpu}}</i-col>
    </Row>

    <h2 class="text-center p-t-20 p-b-10">合同执行额排名</h2>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="2" class="text-left">线路</i-col>
      <i-col span="6">发布费(元)</i-col>
      <i-col span="6">服务费(元)</i-col>
      <i-col span="6">执行额(元)</i-col>
      <i-col span="4">占比</i-col>
    </Row>
    <Row v-for="(item,index) in assetExecuteData" :key="index" :gutter="8" class="p-t-10 text-right">
      <i-col span="2" class="text-left">{{item.name}}</i-col>
      <i-col span="6">{{formatMoneyNoPrefix(item.signUsePrice)}}</i-col>
      <i-col span="6">{{formatMoneyNoPrefix(item.signServicePrice)}}</i-col>
      <i-col span="6">{{formatMoneyNoPrefix(item.signUsePrice + item.signServicePrice)}}</i-col>
      <i-col span="4">{{item.rate}} %</i-col>
    </Row>
    <p v-if="assetExecuteData.length===0" class="remark p-t-20 text-center">暂无数据</p>

    <h2 class="text-center p-t-20 p-b-10">签约额排名</h2>
    <Row  class="table-title text-right" :gutter="8">
      <i-col span="2" class="text-left">线路</i-col>
      <i-col span="6">发布费(元)</i-col>
      <i-col span="6">服务费(元)</i-col>
      <i-col span="6">签约额(元)</i-col>
      <i-col span="4">占比</i-col>
    </Row>
    <Row v-for="(item,index) in assetContractData" :key="'c'+index" :gutter="8" class="p-t-10 text-right">
      <i-col span="2" class="text-left">{{item.name}}</i-col>
      <i-col span="6">{{formatMoneyNoPrefix(item.useValue)}}</i-col>
      <i-col span="6">{{formatMoneyNoPrefix(item.serviceValue)}}</i-col>
      <i-col span="6">{{formatMoneyNoPrefix(item.targetValue)}}</i-col>
      <i-col span="4" class="text-right">{{item.rate}} %</i-col>
    </Row>
    <p v-if="assetContractData.length===0" class="remark p-t-20 text-center">暂无数据</p>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { toMoney, toMoneyNoPrefix, toNumber } from '@/utils/wnumb_own'
import { countExecuteAmountGb, getPublisherPassengerFlow, getLastUpdateDate, listContractAmountGb } from '@/api/dw/datav'
import { listExecuteAmountGbAsset } from '@/api/dw/asset'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,

      pagedata_contractExecuteAmount: '',
      pagedata_PassengerFlow: 0,
      pagedata_LastUpdateDate: '',

      assetExecuteData: [],
      assetContractData: [],

      dataSourcePie: null
    }
  },
  mounted () {
    this.initPageData()
    this.loadExecuteAmountGb()
    this.loadContractData()
  },
  methods: {
    initPageData () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        companyId: this.companyId,
        assetId: this.assetId
      }
      // 获取客流量
      getPublisherPassengerFlow(queryModel).then(res2 => {
        this.pagedata_PassengerFlow = res2[0].value
      })

      // 获取客流量最后一次更新日期
      const queryModel2 = {
        publisherId: this.publisherId,
        companyId: this.companyId,
        assetId: this.assetId
      }
      getLastUpdateDate(queryModel2).then(res => {
        this.pagedata_LastUpdateDate = res[0].value
      })
    },
    loadExecuteAmountGb () {
      const queryModel = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        companyId: this.companyId,
        assetId: this.assetId,
        gbType: 'month',
        productIds: this.productIds.length > 0 ? this.productIds.join(',') : null
      }

      // 获取合同执行额
      countExecuteAmountGb(queryModel).then(res => {
        let totalMoney = 0
        const xData = []
        const yData = []
        res.forEach(element => {
          totalMoney += element.value
          xData.push(element.id)
          yData.push(element.value)
        })

        this.pagedata_contractExecuteAmount = totalMoney
        this.initReport(xData, yData)
      })
    },
    loadContractData () {
      const postData = {
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        startDate: this.searchSchedule.startDate,
        gbType: 'asset',
        assetId: this.assetId,
        companyId: this.companyId,
        totalType: 1,
        productIds: this.productIds.length > 0 ? this.productIds.join(',') : null
      }
      // 合同签约额排名
      listContractAmountGb(postData).then(res => {
        this.assetContractData = res
      })
      // 执行额排名
      listExecuteAmountGbAsset(postData).then(res => {
        this.assetExecuteData = res
      })
    },
    initReport (xData, yData) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        this.dataSourcePie = echarts.init(document.getElementById('workMonthReport'))

        const option = {
          color: ['#2b85e4'],
          xAxis: {
            type: 'category',
            data: xData,
            axisLabel: {
              color: '#c5c8ce'
            }
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              show: false
            },
            splitLine: {
              lineStyle: {
                opacity: 0.1
              }
            }
          },
          grid: {
            top: '10%',
            left: '5%',
            right: '5%',
            bottom: '20%',
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999'
              }
            }
          },
          series: [
            {
              data: yData,
              type: 'bar'
            }
          ]
        }

        this.dataSourcePie.setOption(option, true)
        const that = this
        window.addEventListener('resize', function () {
          that.dataSourcePie.resize()
        })
      })
    },
    formatMoney (money) {
      return toMoney(money)
    },
    formatMoneyNoPrefix (money) {
      return toMoneyNoPrefix(money)
    },
    formatNumber (number) {
      return toNumber(number)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    },
    productIds () {
      return this.$store.state.situationWeb.productIds
    },
    beignUpdateBottomAndRight () {
      return this.$store.state.situationWeb.beignUpdateBottomAndRight
    },
    pagedata_arpu () {
      if (this.pagedata_PassengerFlow === '0') {
        return '￥ 0.00'
      } else {
        return toMoney(parseFloat(this.pagedata_contractExecuteAmount) / parseFloat(this.pagedata_PassengerFlow))
      }
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.loadContractData()
        this.initPageData()
        this.loadExecuteAmountGb()
      }
    },
    beignUpdateBottomAndRight () {
      this.loadContractData()
      this.initPageData()
      this.loadExecuteAmountGb()
    }
  }
}
</script>
